import React, {Component} from 'react'
import Layout from 'components/Layout'
import {Container, Col, Row} from 'reactstrap'
import BoohBannerMob from 'images/booh-sub-576.jpg'
import BoohBannerTablet from 'images/booh-sub-991.jpg'
import BoohBannerDesk from 'images/booh-sub-1920.jpg'
import YouTube from "react-youtube"
import TourList from "components/Tours/USTourList"
import styled from "styled-components"
import {media} from "utils/Media"
import play from "images/icons/play.svg"
import placeholder from "images/trailer-placeholder.jpg"
import Logo from 'images/SW_Master_PT_White.png'

const Play = styled.div`
    &:after {
        content: "";
        position: absolute;
        z-index: 2;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-image: url("${play}");
        background-repeat: no-repeat;
        background-size: contain;
        height: 60px;
        width: 60px;
	    	cursor: pointer;

        @media ${media.md} {
            height: 80px;
            width: 80px;
        }

        @media ${media.lg} {
            height: 110px;
            width: 110px;
        }
    }

    &:hover {
        &:after {
            background-color: red;
        }
    }

    `

const Placeholder = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-image: url("${placeholder}");
    background-size: cover;
    background-position: center;
    cursor: pointer;
`
const StyledContainer = styled(Container)`
    .img {
        width: 70%;
        margin: 2rem 0;
        max-width: 350px;
    }
    .button {
        margin: 1rem 0;
        min-width: 200px;
        max-width :250px;
        background: ${props => props.theme.colors.yellow};
        border: solid 2px ${props => props.theme.colors.yellow};
        transition: all .25s ease-in-out;
        span {
            transition: all .25s ease-in-out;
        }
        &:hover, &:active {
            background: transparent;
            span {
                color: #fff;
            }
        }
    }
    h2 {
        text-transform: uppercase;
        margin: .5rem 0;
        @media ${media.md} {
                font-size: 3.5rem;
            }
    }
    .box {
        max-width: 850px;
        border: solid 3px #fff;
        margin: 1rem auto 1.5rem auto;
        padding: 1.5rem;
        h4 {
            font-size: 2.25rem;
            @media ${media.md} {
                font-size: 3.25rem;
            }
        }
        p {
            strong {
                font-size: 1.25rem;
                font-weight: 700;
            }
        }
    }
`

const headData = {
    title: 'Bat Out Of Hell! - London',
    description: 'he official site for the US Tour of Bat Out of Hell! The Musical based on the songs of Jim Steinman and Meat Loaf',
};

class LondonPage  extends Component {
    constructor(props) {
        super(props)

        this.state = {
            modal: false,
            placeholder: true,
            event: ""
        }

        this.onReady = this.onReady.bind(this)
        this.play = this.play.bind(this)
        this.showPlaceholder = this.showPlaceholder.bind(this)
    }

    onReady(event) {
        this.setState({
            player: event
        })
    }

    play() {
        this.state.player.target.playVideo()
        this.setState({
            placeholder: false
        })
    }

    showPlaceholder() {
        this.setState({
            placeholder: true
        })
    }

    render() {
        const opts = {
            playerVars: { // https://developers.google.com/youtube/player_parameters
                rel: 0,
                modestbranding: 1
            }
        }


        return (
            <>

                <Layout
                    headData={headData}
                    navGlobal={true}
                    uk
                    path="/london/"
                >
                    <div className="page bg--black">

                        <div>
                            <img src={BoohBannerMob} alt="Bat Out Of Hell!" className="img-fluid w-100 d-sm-none"/>
                            <img src={BoohBannerTablet} alt="Bat Out Of Hell!"
                                 className="img-fluid w-100 d-none d-sm-block d-lg-none"/>
                            <img src={BoohBannerDesk} alt="Bat Out Of Hell!" className="img-fluid w-100 d-none d-lg-block"/>
                        </div>

                        <Row>
                            <StyledContainer className="px-0 pt-3 py-md-5 text-center">
                                <Row>
                                    <Col xs={12}>
                                        <div className="col-xs-12 text-center home">
                                            <h1 className="mb-0">BAT OUT OF HELL! ROARS BACK INTO LONDON FOR A STRICTLY LIMITED SEASON</h1>
                                        </div>
                                        <img className="img" src={Logo} />
                                        <h2>17 Feb - 01 April 2023</h2>
                                        <button className="button btn">
                                            <a style={{color: 'black'}} href="https://www.sadlerswells.com/whats-on/jim-steinmans-bat-out-of-hell/" target="_blank" rel="noopener noreferrer">
                                                <span>BOOK NOW</span>
                                            </a>
                                        </button>
                                        <div className="box">
                                            <div>
                                                <h4 className="lh-fix">PERFORMANCE SCHEDULE</h4>
                                                    <p><strong>Monday – Saturday 7.30pm </strong></p>
                                                    <p><strong>&amp;</strong></p>
                                                    <p><strong>Wednesday &amp; Saturday 2.30pm</strong></p>
                                            </div>
                                        </div>
                                        <div className="box">
                                            <div>
                                                <h4>HOW TO BOOK</h4>
                                                 <p><strong>In Person:</strong> Box Office open Monday – Saturday from 12pm-6pm</p>
                                                 <p><strong>Telephone:</strong> 020 7863 8000</p>
                                                 <p><strong>Access:</strong> For details <a href="https://www.sadlerswells.com/your-visit/accessibility/" target="_blank" rel="noopener noreferrer"> Click Here</a> </p>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-9 mx-auto text-center">
                                        <p><strong><em>Bat Out of Hell! The Musical</em></strong> is a Rocky-Horror, Romeo-and-Juliet celebration of star-crossed lovers from opposite sides of the tracks caught in a city teetering on the brink of disaster. When Raven falls in love with the rebellious Strat, all bets are off in this love-at-all-costs, rock-and-rollercoaster story based on uncompromising youth.&nbsp;</p>
<p><strong><em>Bat Out of Hell! The Musical</em></strong> wowed critics and public alike when it played limited seasons at Manchester Opera House, London Coliseum and London&rsquo;s Dominion Theatre from 2017 to 2019. The musical also ran successfully in Canada, Germany and at New York&rsquo;s City Centre in 2019. The current UK and Ireland tour began performances at Manchester Opera House on 11 September 2021 and has been playing to sold out houses and great critical acclaim. The production&nbsp;won the BBC Radio 2 Audience Award for Best Musical at the Evening Standard Awards and was nominated for 8 WhatsOnStage Awards, including Best New Musical.</p>
<p><em>Bat Out of Hell!</em> became one of the best-selling albums in history, selling over 50 million copies worldwide.&nbsp;For the stage musical, the legendary and award-winning Jim Steinman incorporated iconic songs from the <em>Bat Out of Hell</em> albums, including <em>You Took the Words Right Out of My Mouth (Hot Summer Night), Bat Out of Hell, I&rsquo;d Do Anything for Love (But I Won&rsquo;t Do That), Paradise By the Dashboard Light </em>and <em>Two Out of Three Ain&rsquo;t Bad</em>, as well as two previously unreleased songs, <em>What Part of My Body Hurts the Most</em> and <em>Not Allowed to Love</em>.&nbsp;The electrifying rock songs of Mr. Steinman propel an epic story of rebellious youth and passion as Strat, the immortal leader of The Lost, has fallen in love with Raven, the beautiful daughter of the tyrannical ruler Falco.</p>
                                        </div>
                                    </Col>

                                    <Col sm={9} className="mx-auto">
                                        <div className="embed-responsive embed-responsive-16by9">
                                            {this.state.placeholder &&
                                            <>
                                                <Play onClick={this.play}/>
                                                <Placeholder src={placeholder} alt="Watch trailer"
                                                             title="Bat Out of Hell! The Musical – Official Trailer"
                                                             onClick={this.play}/>
                                            </>
                                            }
                                            <YouTube
                                                videoId="o_UmMld4T50"
                                                opts={opts}
                                                onReady={this.onReady}
                                                onEnd={this.showPlaceholder}
                                                className="embed-responsive-item"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </StyledContainer>
                        </Row>

                    </div>

                </Layout>

            </>
        );

    }
}

export default LondonPage 